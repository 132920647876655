@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    body {
        @apply text-neutral-600 antialiased font-sans;
    }

    .btn-primary {
      @apply py-4 px-4 bg-primary-500 hover:bg-primary-600 rounded-4 uppercase text-white text-button text-center;
    }

    a {
        @apply text-primary-500 hover:underline;
    }

    .flex-center {
      @apply justify-center items-center;
    }

    .font-stretch-95 {
        font-stretch: 95%;
    }
  }
